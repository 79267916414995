import anime from 'animejs';
export default function example2(container) {
	const tl = anime.timeline({
		easing: 'easeOutSine'
	});
	tl.add({
		targets: container.querySelectorAll('.word'),
		rotate: ['90deg', '0deg'],
		opacity: [0, 1],
		duration: 1000
	})
}