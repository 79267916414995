import CommonMenu from '../../components/common-header/animations';

const HamburgerMenu = () => {

	const _toggleMenu = () => {
		const commonMenu = CommonMenu();
		const btnMenu = document.querySelector('[data-js-hm-menu]');
		const subMenuItem = document.querySelector('.menu-item-has-children');
		btnMenu.addEventListener('click', function () {
			this.classList.toggle('burger-btn--active');
			document.querySelector('.header__menu-container').style.height = window.innerHeight + 'px';
			if (this.classList.contains('burger-btn--active')) {
				// headerMenu.classList.add("header__content--opened");

				document.querySelector('body').classList.add('no-scroll');
				document.querySelector('html').classList.add('no-scroll');
				commonMenu.init('enter');
				// Dynamic import
				// import(/* webpackChunkName: "toggleMenu" */ "./animations").then(module => {
				// 	module.CommonMenu("enter")
				// });
			} else {
				// headerMenu.classList.remove("header__content--opened");
				document.querySelector('body').classList.remove('no-scroll');
				document.querySelector('html').classList.remove('no-scroll');
				commonMenu.init('exit');
				// Dynamic import
				// import(/* webpackChunkName: "toggleMenu" */ "./animations").then(module => {
				// 	module.CommonMenu("exit")
				// });
			}

		});
		if (window.innerWidth < 1024) {
			subMenuItem.addEventListener('click', function () {
				this.classList.toggle('submenu-toggle');
				if (this.classList.contains('submenu-toggle')) {
					subMenuItem.querySelector('.sub-menu').style.display = 'block';
				} else {
					subMenuItem.querySelector('.sub-menu').style.display = 'none';
				}

			})
		}
	};

	return {
		init: _toggleMenu
	};
};

export default HamburgerMenu;