import Sliders from './carousel';
import SplitStrings from '../utilities/splitStrings';
import HamburgerMenu from '../../components/common-header/script';

const Init = () => {

	const _once = () => {
		const hm = HamburgerMenu();
		hm.init();
	};

	const _beforeEnter = container => {
		const splitStrings = SplitStrings();
		const sliders = Sliders();
		splitStrings.init();
		sliders.init(container);
	};
	const _enter = container => {
		// functions
	};


	return {
		once: _once,
		beforeEnter: _beforeEnter,
		enter: _enter
	}
}

export default Init;